<template>
  <view-wrapper title="询价单">
    <template #header>
      <a-tabs type="card" v-model:activeKey="tabs.quoteStatus" @change="tabs.click" class="tabs-card-self">
        <a-tab-pane
          :key="item.code"
          :tab="item.message"
          v-for="item in status.data.value"
          :disabled="table.loading"
        ></a-tab-pane>
      </a-tabs>
    </template>
    <div style="background: #fff">
      <form-query :model="form.data" style="padding: 24px" @search="form.search">
        <a-row>
          <a-col :span="7">
            <a-form-item label="询价单号">
              <a-input v-model:value="form.data.serialNo" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="VIN码">
              <a-input v-model:value="form.data.vin" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="采购商">
              <a-input v-model:value="form.data.tenantShortName" placeholder="请输入" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="询价日期">
              <e-datepicker
                type="range"
                v-model:start="form.data.preparedStartDate"
                v-model:end="form.data.preparedEndDate"
              ></e-datepicker>
            </a-form-item>
          </a-col>
          <a-col :span="3" :offset="14" class="flex justify-end">
            <a-button @click="form.init">重置</a-button>
            <!-- <a-button @click="form.search" style="margin-left: 10px" type="primary">查询</a-button> -->
          </a-col>
        </a-row>
      </form-query>
      <div style="margin-top: 8px; padding: 0 24px">
        <e-table
          pagination
          :paging="table.paging"
          :loading="table.loading"
          :data-source="table.data"
          :columns="table.columns"
          rowKey="id"
          :method="table.get"
          class="mytable"
        >
          <template #index="{ index }">
            <span>{{ index + 1 }}</span>
          </template>
          <template #vehicleSpec="{ record }">
            <span>{{
              record.vin
                ? `${record.vehicleBrand}${record.vehicleSubBrand ? ' ' + record.vehicleSubBrand : ''} ${
                    record.vehicleSystem
                  } ${record.vehicleYear} ${record.vehicleDisplacement}`
                : record.vehicleBrand
            }}</span>
          </template>
          <template #branchName="{ text, record }">
            <a-tooltip :title="text">
              <span>{{ record.tenantShortName }}</span>
            </a-tooltip>
          </template>
          <template #commitBy="{ text, record }">
            <span>{{ text }}</span>
            <a-tooltip :title="record.enocloudCommitUser.cellphone || '暂无'">
              <PhoneFilled
                class="cursor-pointer"
                style="color: #0d8d8d; transform: rotateY(180deg); margin-left: 8px"
              />
            </a-tooltip>
            <MessageFilled
              v-if="record.chatUser"
              @click="webim.open(record.chatUser)"
              class="cursor-pointer operation-model"
              style="color: #ffd100; margin-left: 8px"
            />
          </template>
          <template #commitDatetime="{ text }">
            <span>{{ formatDate(text) }}</span>
          </template>
          <template #operation="{ record }">
            <span class="operation-model">
              <a class="text-primary" @click="table.qutationDetail.click(record)">{{
                tabs.quoteStatus === 'P' ? '去报价' : '追加报价'
              }}</a>
              <a-button
                type="link"
                v-if="isAuthenticated && record.purchased.code === 'Y'"
                @click="table.purchaseDetail.click(record)"
                >查看订单</a-button
              >
            </span>
          </template>
        </e-table>
      </div>
    </div>
  </view-wrapper>
</template>

<script lang="ts">
import { defineComponent, reactive, PropType, computed } from 'vue'
import { Tabs as ATabs, TabPane as ATabPane, Row as ARow, Col as ACol, Tooltip as ATooltip } from 'ant-design-vue'
import { PhoneFilled, MessageFilled } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { useTable, useAjax, useStore, formatDate, useWebim } from '@vue-mfe/utils'

const columns = [
  { title: '序号', slots: { customRender: 'index' } },
  { title: '询价单号', dataIndex: 'serialNo' },
  { title: 'VIN码', dataIndex: 'vin' },
  { title: '车型', slots: { customRender: 'vehicleSpec' } },
  { title: '采购商', dataIndex: 'branchName', slots: { customRender: 'branchName' } },
  { title: '询价人', dataIndex: 'enocloudCommitUser.name', slots: { customRender: 'commitBy' } },
  { title: '种类数', dataIndex: 'goodsKindCount' },
  { title: '询价时间', dataIndex: 'commitDatetime', slots: { customRender: 'commitDatetime' } },
  { title: '操作', slots: { customRender: 'operation' } }
]

export default defineComponent(
  {
    name: '',
    props: {
      inquiryId: String as PropType<string>
    },
    components: {
      ARow,
      ACol,
      ATooltip,
      ATabs,
      ATabPane,
      // ASelect,
      PhoneFilled,
      MessageFilled
    },
    setup (props) {
      const store = useStore()
      const router = useRouter()
      const webim = useWebim()

      const status = useAjax(
        {
          action: 'GET /common/lookup/QOTSTATUS'
        }
      )
      const tabs = reactive(
        {
          quoteStatus: 'P',
          click () {
            table.paging.pageIndex = 1
            table.get()
          }
        }
      )
      const form = reactive(
        {
          data: {
            serialNo: '',
            vin: '',
            tenantShortName: '',
            preparedStartDate: '',
            preparedEndDate: ''
          },
          init () {
            form.data.serialNo = ''
            form.data.vin = ''
            form.data.tenantShortName = ''
            form.data.preparedStartDate = ''
            form.data.preparedEndDate = ''
          },
          search () {
            table.get()
          }
        }
      )
      const table = useTable(
        {
          ajax: {
            get: {
              action: 'GET /supplier/quotation/inquiry',
              params: () =>
                props.inquiryId
                  ? Object.assign({ quoteStatus: tabs.quoteStatus, inquiryId: props.inquiryId }, form.data)
                  : Object.assign({ quoteStatus: tabs.quoteStatus }, form.data)
            }
          },
          columns,
          qutationDetail: {
            click (row: any) {
              router.push({ path: `/supplier/supply/quote/${row.id}` })
            }
          },
          addQutationDetail: {
            click (row: any) {
              router.push({ path: `/supplier/supply/quote/${row.id}` })
            }
          },
          purchaseDetail: {
            click (row: any) {
              router.push(
                {
                  path: '/supplier/supply/order',
                  query: { inquiryId: row.id }
                }
              )
            }
          }
        }
      )

      const isAuthenticated = computed(() => ['A', 'D'].includes(store.state.supplier.status.code))
      return {
        tabs,
        form,
        table,
        status,
        formatDate,
        isAuthenticated,
        webim
      }
    }
  }
)
</script>
